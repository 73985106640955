<app-header *ngIf="globals.isLoggedIn"></app-header>
<div class="main_wrapper">
  <div class="loader" *ngIf="globals?.isLoading">
    <div class="loader-circle"></div>
    <span class="loader-text">Loading...</span>
  </div>
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer>
