<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div class="copyright_terms_block">
                    &copy; Copyright <span class="footer_year">2024</span> <a href="https://www.stateside.com/" target="_blank"> Stateside </a> <span class="px-1"> |</span> Powered by <a href="https://www.theopeneyes.com/" target="_blank">OpenEyes Technologies Inc.</a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                <ul class="footer_menu_block">
                   <li><a target="_blank" href="https://www.stateside.com/who-we-are">About us</a></li>
                   <li><a target="_blank" href="https://www.stateside.com/privacy-policy">Privacy Policy</a></li>
                   <li><a target="_blank" href="#">Terms of use</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<div class="clearfix"></div>